import React, { useState } from "react";

const About = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleNetworkList = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const networkListData = [
    {
      year: 2001,
      title: "Unicampus Education Network",
      logo: "../assets/images/unicampus_logo.svg",
      link: "https://www.myunicampus.com/site/index.php",
      content:
        "Unicampus, a part of SoftEd Group is one of the oldest and most established education consultancies in Nepal since 2001 A.D. we assist students to pursue their higher education in many countries like Australia, the USA, the UK, and Canada.",
    },
    {
      year: 2010,
      title: "Softwarica College of IT and E-commerce",
      logo: "../assets/images/softwarica_logo.svg",
      link: "https://www.softwarica.edu.np/",
      content:
        "Softwarica College of IT and E-commerce, a part of the SoftEd Group, is a pioneer in British higher education within Nepal.  Since its founding, the college has focused on a hands-on learning approach to produce industry-ready IT graduates. Students benefit from personality development classes, entrepreneurial exposure,  and expert guidance alongside their technical coursework, ensuring career success within Nepal and abroad.",
    },
    {
      year: 2010,
      title: "Sunway International Business School",
      logo: "../assets/images/sunway_logo.svg",
      link: "https://sunway.edu.np/",
      content:
        "Sunway College, a part of the SoftEd Group, is a top IT college in Nepal. Since its establishment in 2010, the college has focused on academic excellence and developing highly skilled IT professionals.  In partnership with Birmingham City University (BCU), Sunway College offers students international exposure and a focus on innovation, responsibility, and adaptability.",
    },
    {
      year: 2019,
      title: "Digi School Global",
      logo: "../assets/images/digischool_logo.svg",
      link: "https://digischoolglobal.com/",
      content:
        "DigiSchool, a part of the SoftEd Group, is an online learning platform dedicated to offering diverse, flexible, and high-quality education. With expert instructors and a wide range of engaging courses, DigiSchool focuses on developing practical skills for personal and professional growth. Their commitment to interactive learning, community connection, and recognized certifications makes DigiSchool an excellent choice for anyone seeking to expand their knowledge.",
    },
    // {
    //   year: 2018,
    //   title: "The Culture Resort",
    //   logo: "../assets/images/cultureresort_logo.svg",
    //   content:
    //     "The Culture Resort, a part of the SoftEd Group, offers a unique blend of traditional Nepali elements and modern luxury alongside the shores of Fewa Lake. With stunning views, contemporary amenities, a garden space, diverse cuisine, and a vibrant open bar, The Culture Resort promises an unforgettable stay immersed in local flavor.",
    // },

    {
      year: 2021,
      title: "Digi Medical",
      logo: "../assets/images/digimedical_logo.svg",
      link: "https://app.digimedicalsewa.com/",
      // linkName: "Visit Site"
      content:
        "Digi Medical, a part of the SoftEd Group, is Nepal's premier provider of healthcare programs designed for a variety of settings.  They offer specialized programs tailored for Montessori schools, corporations, and even families, promoting health and well-being across the nation.",
    },
    {
      year: 2021,
      title: "Digi Technology",
      logo: "../assets/images/digitechnology_logo.svg",
      link: "https://digitechnologynepal.com/",
      content:
        "Digi Technology, a part of the SoftEd Group, is a global technology solutions provider with locations in Nepal and the USA. Their diverse team of certified experts offers a full range of IT services, including web and mobile development, cybersecurity, data management, and client communications.",
    },
    {
      year: 2021,
      title: "UK COLLEGES",
      logo: "../assets/images/ukcolleges_logo.svg",
      link: "https://digitechnologynepal.com/",
      content:
      "UK Colleges, a part of the SoftEd Group, is a network of higher education institutions offering diverse foundation, bachelor's, and master's degree programs.  Focused on providing international exposure for its students, UK Colleges prepares graduates for success in a globalized world.",
    },
    
    {
      year: 2023,
      title: "Digiworld",
      logo: "../assets/images/digiworld_logo.svg",
      link: "https://digiworld.ltd/",
      content:
        "Digiworld.ltd, Nepal's upcoming premier super app, where convenience meets innovation. . Founded by Mr. Bigyan Shrestha, former Co-Founder of Gyapu.com, Digiworld.ltd is poised to redefine the online shopping experience for Nepalese consumers. Digiworld.ltd, our vision is simple yet profound: to empower Nepal's digital marketplace by offering a comprehensive range of services all in one convenient platform. From ecommerce to edtech, healthtech to OTA (Online Travel Agency), we aim to cater to the diverse needs of our customers, making their digital lives easier and more hassle-free than ever before. Unlike traditional apps that focus on one specific niche, Digiworld.ltd is breaking barriers by offering a wide array of services under one roof. Whether you're a tech-savvy shopper, a parent looking for educational resources for your children, a health-conscious individual seeking wellness solutions, or a traveler in need of seamless booking experiences, Digiworld.ltd has got you covered.",
    },
    // {
    //   year: 2021,
    //   title: "Paramarsha Property",
    //   logo: "../assets/images/paramarsha_logo.svg",
    //   content:
    //     "Paramarsha Property, a part of the SoftEd Group, is Nepal's leading real estate platform focused on simplifying the home buying and selling process. Their innovative online platform empowers users to effortlessly search for and connect with properties that meet their needs.",
    // },
    // {
    //   year: 2022,
    //   title: "Mini Australia",
    //   logo: "../assets/images/miniaustralia_logo.svg",
    //   content:
    //     "Mini Australia, a part of the SoftEd Group, brings the vibrant spirit of Australia to Nepal. Discover the allure of Aussie life, explore educational opportunities, and experience a unique cultural connection at Mini Australia.",
    // },
  ];

  const qualityList = [
    {
      title: "Quality education",
      content:
        "We believe in power of education that helps to transform the lives of people through high-quality education. We believe through our education our students succeed, countries prosper, and societies benefit",
    },
    {
      title: "24/7 student services",
      content:
        "We offer resources for students that may be required in daily basis. Services like peer mentoring programs, academic support, job and housing services, clubs and societies, child care, therapy and medical services is open 24/7 in each of our institution.",
    },
    {
      title: "Study mode",
      content:
        "You will notice that different study modes are provided to students by each of our institution. This can substantially narrow down the shortlist of suitable institutions if you need a part-time , full time, on campus or distance education alternative.    ",
    },
  ];
  return (
    <>
      <section>
        <div className="element4">
          <img src="../assets/images/element4.png" alt="" />
        </div>
        <div className="about-top">
          <div className="about-icon">
            <a href="/">
              <i class="fa-solid fa-house"></i>
            </a>
            <i class="fa-solid fa-angle-right"></i>
            <p> About Us</p>
          </div>
          <div className="about-top-text">
            <h2>About Us</h2>
            <p>
              Softed is a group of companies delivering services, emphasizing
              technology in particular. We started with the major goal of
              educating people about technology and ended up educating them
              through technology; we have come a long way since we started,
            </p>
          </div>
          <div className="about-top-last">
            <div className="about-top-elem">
              <img src="../assets/icons/piechart.svg" alt="piechart" />
              <p>Observe</p>
            </div>
            <div className="about-top-elem">
              <img src="../assets/icons/announcement.svg" alt="announcement" />
              <p>Innovate</p>
            </div>
            <div className="about-top-elem elem3">
              <img src="../assets/icons/desktop.svg" alt="desktop" />
              <p>Deliver</p>
            </div>
          </div>
        </div>
      </section>
      <section className="about-company">
        <div className="about-container">
          <div className="container-num">
            <p>01</p>
            <img src="../assets/icons/vision.svg" alt="" />
          </div>
          <h2>
            Our <br />
            Vision
          </h2>
          <p>
            SoftED vision is to provide leadership in the country's overall
            growth through a network of businesses that seek to elevate our
            clients, partners, and employees to a level that allows us to build
            the most successful and merry multi-cultural business community in
            which everyone is a proud member of the Softed family.
          </p>
        </div>
        <div className="about-container about-container-mid">
          <div className="container-num">
            <p>02</p>
            <img src="../assets/icons/mission.svg" alt="" />
          </div>
          <h2>
            Our <br />
            Mission
          </h2>
          <p>
            In a globally competitive market, we strive to constantly provide
            the most efficient and high-quality product and services while also
            giving comprehensive value and establishing an environmentally
            responsible workplace for our employees and customers.
          </p>
        </div>
        <div className="about-container">
          <div className="container-num">
            <p>03</p>
            <img src="../assets/icons/choose_us.svg" alt="" />
          </div>
          <h2>
            Why <br />
            Choose Us?
          </h2>
          <p id="check">
            We believe in quality and provide our society with a quality
            services which brings change in the lives of people through us.
          </p>
        </div>
      </section>
      <section>
        <div className="element5">
          <img src="../assets/images/element5.png" alt="" />
        </div>
        <div className="about-work">
          <div className="about-work-container">
            <div className="about-work-img">
              <img src="../assets/images/about_work1.svg" alt="" />
            </div>
            <div className="about-work-text">
              <h2>SoftED for Everyone</h2>
              <p>
                Softed is a group of companies delivering services, emphasizing
                technology in particular. We started with the major goal of
                educating people about technology and ended up educating them
                through technology; we have come a long way since we started.
                Shaped by a diverse group of entrepreneurial people working
                together to create a diverse business, we have pushed our
                boundaries by not just limiting ourselves to education and
                technology, but also expanding through a sub domain “Softed
                Business” which is also entering into various business domains
                such as tourism, healthcare and ecommerce. Working towards the
                overall development of society with a wide range of services
                available for each age group, we have taken an initiation called
                “Softed for everyone”, where everyone will profit or thrive as a
                result of its services and job possibilities.
              </p>
            </div>
          </div>
          <div className="about-work-container work-container2">
            <div className="about-work-text">
              <h2>Contributing more bricks to the nation’s development</h2>
              <p>
                Softed Group currently employs over 500 people who work for a
                variety of firms and have offered invaluable services as well as
                business and job prospects for our employees. Our organization
                will continue to expand in the future, contributing more bricks
                to the nation’s development. We believe that each organization
                has the potential to make a significant difference in the world.
                As a result, we are dedicated to the betterment of the country
                by providing numerous chances for the country’s youth and
                country’s growth.
              </p>
            </div>
            <div className="about-work-img">
              <img src="../assets/images/about_work2.svg" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className="about-services">
        <div className="services-text">
          <p>
            We impart quality service that could reach to all consumer with
            varied economic backgrounds.
          </p>
        </div>
        <div className="services-container">
          <div className="services-box">
            <div className="services-box-icon">
              <img src="../assets/icons/box_icon.svg" alt="" />
            </div>
            <div className="services-box-text">
              <h2>Life Changing Quality services</h2>
              <p>
                Through our extra ordinary and excellent service, we bring the
                possibilities of the digital age to the real world, bringing
                changes to people's lives.
              </p>
            </div>
          </div>
          <div className="services-box">
            <div className="services-box-icon">
              <img src="../assets/icons/box_icon.svg" alt="" />
            </div>
            <div className="services-box-text">
              <h2>Help You Choose Career Path</h2>
              <p>
                We have a various course and institution option available for
                you to choose from where we can lead your career in right
                direction.
              </p>
            </div>
          </div>
          <div className="services-box">
            <div className="services-box-icon">
              <img src="../assets/icons/box_icon.svg" alt="" />
            </div>
            <div className="services-box-text">
              <h2>
                Fulfilling Gap Between Quality Services and Client Satisfaction
              </h2>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="element6">
          <img src="../assets/images/element6.png" alt="" />
        </div>
        <div className="about-network" id="ourCompanies">
          <div className="about-network-heading">
            <h2>
              OUR <br />
              <span>NETWORK</span>
            </h2>
            <p>
              Every one of our institutions has its own extraordinary image and
              programs for students who flourish to have global introduction. We
              persistently improve to offer the highest quality education and
              administrations with the polished skill that has made us pioneers
              in the field. As our network continues to evolve our precepts
              remain the same: our trust in the ability of education to
              transform lives which led us to founder to formalize and
              consolidate all current institutions under one umbrella called
              Softed.
            </p>
          </div>
          <div className="about-network-img">
            <img src="../assets/images/about_network.jpg" alt="" />
          </div>

          {networkListData.map((network, index) => (
            <div className="about-network-list" key={index}>
              <div
                className="network-list-title"
                onClick={() => toggleNetworkList(index)}
              >
                <div className="network-list-title-h3">
                  <span>{network.year}</span>
                  <h3>{network.title}</h3>
                </div>
                <div className="network-arrow">
                  <i
                    className={`fa-solid ${
                      activeIndex === index ? "fa-arrow-up" : "fa-arrow-down"
                    }`}
                  ></i>
                </div>
              </div>

              {activeIndex === index && (
                <div className="network-list-content-mid">
                  <div className="network-list-content">
                    <div className="network-logo-link">
                      <a
                        href={network.link}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          textDecoration: "none",
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={network.logo} alt="" />
                        <p
                          style={{
                            fontWeight: "bold",
                            textDecoration: "underline",
                            marginTop: "15px",
                          }}
                        >
                          Visit Site
                        </p>
                      </a>
                    </div>
                    <p>{network.content}</p>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </section>

      <section className="about-quality">
        <h2>
          We believe in quality and provide our society with a quality services
          which brings change in the lives of people through us.
        </h2>
        <div className="quality-section">
          <div className="quality-img">
            <img src="../assets/images/Bitmap.svg" alt="" />
          </div>

          <div className="quality-content">
            {qualityList.map((quality, index) => (
              <div className="quality-content-list" key={index}>
                <h3>{quality.title}</h3>
                <p>{quality.content}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};
export default About;
